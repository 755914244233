import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import styles from './WeeklyHighscores.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import Img from 'gatsby-image';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

//Images
import StarsTop from '@images/projects/blast-galaxy/weekly-highscores-stars-top.svg';

export const WeeklyHighscores = props => {
    const { title, text, imageAlt } = props;

    const imageData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "projects/blast-galaxy/weekly-highscores.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <section className={styles.weeklyHighscores}>
            <Container className={styles.weeklyHighscores__container}>
                <StarsTop className={styles.weeklyHighscores__starsTop} />

                {title && (
                    <FadeInWhenVisible>
                        <h3 className={`h5 ${styles.weeklyHighscores__title}`}>{title}</h3>
                    </FadeInWhenVisible>
                )}

                {imageData && (
                    <FadeInWhenVisible threshold={0.5}>
                        <Img
                            className={styles.weeklyHighscores__img}
                            fluid={imageData.file.childImageSharp.fluid}
                            alt={imageAlt}
                        />
                    </FadeInWhenVisible>
                )}

                <div className={styles.weeklyHighscores__textBox}>
                    {text &&
                        text.map((p, index) => {
                            return (
                                <FadeInWhenVisible threshold={0.15} key={index}>
                                    <p className="big">{p}</p>
                                </FadeInWhenVisible>
                            );
                        })}
                </div>
            </Container>
        </section>
    );
};
