import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const Dust = props => {
    const { className } = props;

    const imageData = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "projects/blast-galaxy/planets/dust.gif" }) {
                publicURL
            }
        }
    `);

    if (!imageData) return null;

    return (
        <img
            className={className}
            loading="eager"
            src={imageData.image.publicURL}
            alt="Dust planet"
            width="100"
            height="100"
        />
    );
};
