import React from 'react';

//Data
import { blastGalaxy as data } from '@data/en/projects/items/blast-galaxy';

//Styles
import styles from '@styles/work/BlastGalaxy.module.scss';

//Components
import { FontWrapper } from '@components/Utilities/FontWrapper/FontWrapper';
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { ProjectHero } from '@components/ProjectsComponents/ProjectHero/ProjectHero';
import { Content } from '@components/Content/Content';
import { ProjectsList } from '@components/ProjectsList/ProjectsList';
import { Content as BlastContent } from '@components/ProjectsComponents/BlastGalaxy/Content/Content';
import { TechStack } from '@components/ProjectsComponents/TechStack/TechStack';
import { GalaxyTitle } from '@components/ProjectsComponents/BlastGalaxy/GalaxyTitle/GalaxyTitle';
import { PlanetarySystem } from '@components/ProjectsComponents/BlastGalaxy/PlanetarySystem/PlanetarySystem';
import { WeeklyHighscores } from '@components/ProjectsComponents/BlastGalaxy/WeeklyHighscores/WeeklyHighscores';
import { ArcadeFrame } from '@components/ProjectsComponents/BlastGalaxy/ArcadeFrame/ArcadeFrame';
// import { Video } from '@components/ProjectsComponents/BlastGalaxy/Video/Video';
import { Navigation } from '@components/ProjectsComponents/BlastGalaxy/Navigation/Navigation';
import { Assets } from '@components/ProjectsComponents/BlastGalaxy/Assets/Assets';
import { Review } from '@components/ProjectsComponents/BlastGalaxy/Review/Review';
import { ProjectQuestion } from '@components/ProjectsComponents/ProjectQuestion/ProjectQuestion';
import { Footer } from '@components/Footer/Footer';
import { Awwwards } from '@components/ProjectsComponents/Awwwards/Awwwards';

//Videos
import BackgroundVideo from '@static/videos/blast-galaxy-background.mp4';

const BlastGalaxy = () => {
    const {
        seo,
        path,
        title,
        hero,
        thumbnail,
        services,
        client_details,
        the_client,
        the_project,
        tech_stack,
        traveling,
        the_concept,
        planetary_system,
        weekly_highscores,
        arcade_frame_intro,
        arcade_frame_explained,
        // glitch,
        navigation,
        assets,
        review,
        project_question,
        other_projects,
        awwwards,
    } = data;

    return (
        <>
            <SEO {...seo} path={path} />

            <Header white />

            <Awwwards href={awwwards.href} />

            <div className={styles.blastGalaxy}>
                <ProjectHero
                    title={title}
                    backgroundColor={hero.background_color}
                    services={services}
                    clientDetails={client_details}
                    image={thumbnail.image}
                    background={{ type: 'video', video: BackgroundVideo }}
                />

                <Content fullHeight title={the_client.title} components={the_client.components} />

                <Content
                    fullHeight
                    black
                    title={the_project.title}
                    components={the_project.components}
                />

                <TechStack
                    title={tech_stack.title}
                    text={tech_stack.text}
                    tools={tech_stack.tools}
                />

                <FontWrapper className={styles.fontWrapper}>
                    <GalaxyTitle title={traveling.title} />

                    <BlastContent
                        small
                        title={the_concept.title}
                        components={the_concept.components}
                    />

                    <PlanetarySystem
                        title={planetary_system.title}
                        text={planetary_system.text}
                        planets={planetary_system.planets}
                    />

                    <WeeklyHighscores
                        title={weekly_highscores.title}
                        imageAlt={weekly_highscores.image_alt}
                        text={weekly_highscores.text}
                    />

                    <ArcadeFrame>
                        <GalaxyTitle title={arcade_frame_intro.title} />

                        <BlastContent small components={arcade_frame_explained.components} />

                        {/* <Video
                            title={glitch.title}
                            video={glitch.video.src}
                            textPopUp={glitch.text_pop_up}
                            textSound={glitch.text_sound}
                        /> */}

                        <Navigation
                            title={navigation.title}
                            firstText={navigation.first_text}
                            secondText={navigation.second_text}
                        />

                        <Assets title={assets.title} text={assets.text} />

                        <Review title={review.title} text={review.text} from={review.from} />
                    </ArcadeFrame>
                </FontWrapper>
            </div>

            <ProjectQuestion title={project_question.title} employee={project_question.employee} />

            <ProjectsList small title={other_projects.title} projects={other_projects.projects} />

            <Footer />
        </>
    );
};

export default BlastGalaxy;
