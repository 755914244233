import React from 'react';

//Styles
import styles from './Navigation.module.scss';
import globalPageStyles from '@styles/work/BlastGalaxy.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';

//Images
import { Main as MainImage } from './images/Main';
import { Drink as DrinkImage } from './images/Drink';
import MenuBackground from '@images/projects/blast-galaxy/menu-button-background.svg';
import CursorPointer from '@images/projects/blast-galaxy/cursor-pointer.svg';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const Navigation = props => {
    const { title, firstText, secondText } = props;

    return (
        <section className={styles.navigation}>
            <Container className={styles.navigation__container}>
                <DrinkImage className={styles.navigation__drinkImg} />

                <MainImage
                    className={`${styles.navigation__mainImg} ${globalPageStyles.blastGalaxyFloat}`}
                />

                <div className={`${styles.navigation__textBox} ${styles.navigation__textBoxOne}`}>
                    {title && (
                        <FadeInWhenVisible>
                            <h2 className="h5">{title}</h2>
                        </FadeInWhenVisible>
                    )}

                    {firstText &&
                        firstText.map((p, index) => {
                            return (
                                <FadeInWhenVisible threshold={0.15} key={index}>
                                    <p className={`big`}>{p}</p>
                                </FadeInWhenVisible>
                            );
                        })}
                </div>

                <div className={`${styles.navigation__textBox} ${styles.navigation__textBoxTwo}`}>
                    <FadeInWhenVisible>
                        <div className={styles.navigation__example}>
                            <h6 className={`${styles.navigation__example__title}`}>
                                <span>Blast Galaxy</span>
                            </h6>
                            <span className={`${styles.navigation__example__button}`}>
                                <span>Menu</span>

                                <MenuBackground
                                    className={`${styles.navigation__example__button__background}`}
                                />
                                <CursorPointer
                                    className={`${styles.navigation__example__button__cursorPointer}`}
                                />
                            </span>
                        </div>
                    </FadeInWhenVisible>

                    {secondText &&
                        secondText.map((p, index) => {
                            return (
                                <FadeInWhenVisible threshold={0.15} key={index}>
                                    <p className={`big`}>{p}</p>
                                </FadeInWhenVisible>
                            );
                        })}
                </div>
            </Container>
        </section>
    );
};
