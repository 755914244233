import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export const Main = props => {
    const { className } = props;

    const mainImageData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "projects/blast-galaxy/homepage.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    if (!mainImageData) return null;

    return (
        <div className={className}>
            <Img
                loading="eager"
                fluid={mainImageData.file.childImageSharp.fluid}
                alt="Blast galaxy homepage screenshot"
            />
        </div>
    );
};
