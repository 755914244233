import React from 'react';

//Styles
import styles from './Review.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';

//Images
import orderGuyImg from '@images/projects/blast-galaxy/blast-galaxy-order-guy.png';

export const Review = props => {
    const { title, text, from } = props;

    return (
        <section className={styles.review}>
            <Container>
                <div className={styles.review__contentBox}>
                    <div>
                        {title && <h4 className={'h5'}>{title}</h4>}

                        {text.map((p, index) => {
                            return (
                                <p className="big" key={index}>
                                    {/* Quotationmark if its the first paragraph */}
                                    {index === 0 ? (
                                        <span className={styles.quoteSlider__quotationMark}>
                                            &#8220;
                                        </span>
                                    ) : null}

                                    {p}

                                    {/* Quotationmark if its the last paragraph */}
                                    {index === text.length - 1 ? (
                                        <span className={styles.quoteSlider__quotationMark}>
                                            &#8221;
                                        </span>
                                    ) : null}
                                </p>
                            );
                        })}
                    </div>

                    <div className={styles.review__fromWrapper}>
                        {from && <p className={styles.review__from}>{from}</p>}

                        <img
                            className={styles.review__img}
                            src={orderGuyImg}
                            alt="Blast Galaxy order guy"
                            loading="eager"
                        />
                    </div>
                </div>
            </Container>
        </section>
    );
};
