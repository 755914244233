import React from 'react';

export const FontWrapper = props => {
    const { className, children } = props;

    if (!children) return null;
    if (!className) return children;

    return <div className={className}>{children}</div>;
};
