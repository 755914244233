import React from 'react';

//Styles
import styles from './Assets.module.scss';
import globalPageStyles from '@styles/work/BlastGalaxy.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { Billboard } from '@components/ProjectsComponents/BlastGalaxy/Billboard/Billboard';

//Images
import { Nebula } from './Images/Nebula';
import { Lava } from './Images/Lava';
import { Dust } from './Images/Dust';
import { Restaurant } from './Images/Restaurant';

export const Assets = props => {
    const { title, text } = props;

    return (
        <section className={styles.assets}>
            <div className={styles.assets__backgroundImgWrapper}>
                <Nebula className={`${styles.assets__nebula}`} />
                <Lava className={`${styles.assets__lava}`} />
                <Dust className={`${styles.assets__dust}`} />
            </div>

            <Container small>
                <Billboard
                    className={`${styles.assets__contentBox} ${globalPageStyles.blastGalaxyFloat}`}
                >
                    {title && <h2 className={`${styles.assets__title} h5`}>{title}</h2>}

                    {text &&
                        text.map((p, i) => {
                            return (
                                <p className="big" key={i}>
                                    {p}
                                </p>
                            );
                        })}
                </Billboard>
            </Container>

            <Restaurant
                className={`${styles.assets__restaurant} ${globalPageStyles.blastGalaxyFloat}`}
            />
        </section>
    );
};
