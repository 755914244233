import React, { useEffect, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import styles from './GalaxyTitle.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';

export const GalaxyTitle = props => {
    const { title } = props;
    const [duration, setDuration] = useState(
        typeof window !== 'undefined' ? window.innerHeight / 2 : 0,
    );

    const imageData = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "projects/blast-galaxy/blast-galaxy-background.svg" }) {
                publicURL
            }
        }
    `);

    useEffect(() => {
        const updateDuration = () => {
            setDuration(typeof window !== 'undefined' ? window.innerHeight / 2 : 0);
        };

        window.addEventListener('resize', updateDuration);

        return () => {
            window.removeEventListener('resize', updateDuration);
        };
    }, []);

    if (!title) return null;

    return (
        <div className={styles.galaxyTitle}>
            {imageData.image && (
                <img
                    loading="eager"
                    src={imageData.image.publicURL}
                    className={styles.galaxyTitle__bgImg}
                    alt="Blast Galaxy"
                />
            )}

            <Controller>
                <Scene duration={duration} triggerHook="0.5">
                    {progress => (
                        <div className={styles.galaxyTitle__wrapper}>
                            <Container>
                                <Timeline totalProgress={progress} paused>
                                    <Tween from={{ scale: 0 }} to={{ scale: 1 }} ease="none">
                                        <h4
                                            className={`h4 ${styles.galaxyTitle__title}`}
                                            dangerouslySetInnerHTML={{ __html: title }}
                                        ></h4>
                                    </Tween>
                                </Timeline>
                            </Container>
                        </div>
                    )}
                </Scene>
            </Controller>
        </div>
    );
};
