import React from 'react';

//Styles
import styles from './Billboard.module.scss';

export const Billboard = props => {
    const { children, className } = props;

    return (
        <div className={`${styles.billboard} ${className}`}>
            <div className={styles.billboard__innerBorders}>
                <div className={styles.billboard__background}>
                    <div className={styles.billboard__tl}></div>
                    <div className={styles.billboard__tr}></div>
                    <div className={styles.billboard__bl}></div>
                    <div className={styles.billboard__br}></div>
                </div>
            </div>

            <div className={styles.billboard__inner}>{children}</div>
        </div>
    );
};
