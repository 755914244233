import React, { useEffect, useState, useRef } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import OnImagesLoaded from 'react-on-images-loaded';

//Styles
import styles from './ArcadeFrame.module.scss';

export const ArcadeFrame = props => {
    const { children } = props;
    const [innerFrameSize, setInnerFrameSize] = useState();
    const innerFrameRef = useRef();
    const [tweenEnd, setTweenEnd] = useState();
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const updateFrameSize = () => {
            const innerFrameChildren = document.querySelector('#inner-frame-children');
            const innerFrameChildrenRect = innerFrameChildren.getBoundingClientRect();
            setInnerFrameSize(innerFrameChildrenRect.height);

            const innerFrameRect = innerFrameRef.current.getBoundingClientRect();
            setTweenEnd(-Math.abs(innerFrameChildrenRect.height - innerFrameRect.height));
        };

        updateFrameSize();
        window.addEventListener('resize', updateFrameSize);

        return () => {
            window.removeEventListener('resize', updateFrameSize);
        };
    }, [imagesLoaded]);

    return (
        <div className={styles.frame}>
            <OnImagesLoaded onLoaded={() => setImagesLoaded(true)} timeout={99999999999}>
                <Controller>
                    <Scene duration={innerFrameSize} pin triggerHook="0">
                        {progress => (
                            <div className={styles.frame__background}>
                                <div ref={innerFrameRef} className={styles.frame__inner}>
                                    <Timeline totalProgress={progress} paused>
                                        <Tween
                                            from={{ x: 0, y: 0, z: 0 }}
                                            to={{ x: 0, y: tweenEnd, z: 0 }}
                                            ease="none"
                                        >
                                            <div
                                                id="inner-frame-children"
                                                className={styles.frame__innerFrameChildren}
                                            >
                                                {children}
                                            </div>
                                        </Tween>
                                    </Timeline>
                                </div>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </OnImagesLoaded>
        </div>
    );
};
