import { mikeyHaklander } from '@data/en/employees/items/mikey-haklander';
import { CONTENT_COMPONENT_TYPES, DEVTOOLS } from '@lib/constants';

export const blastGalaxy = {
    path: '/work/blast-galaxy',
    seo: {
        title: 'Blast Galaxy',
        description:
            'To grow digital revenues and extend its online presence, Blast galaxy wanted a website with the spirit of an 80s arcade.',
    },
    title: 'In a Galaxy not so very far, far away...',
    awwwards: {
        href: 'https://www.awwwards.com/sites/blast-galaxy',
    },
    thumbnail: {
        background_color: '#0F0C24',
        image: {
            path: 'projects/blast-galaxy/blast-galaxy-thumbnail.png',
            alt: 'Blast Galaxy arcade',
        },
    },
    live_demo: 'https://blastgalaxy.nl',
    hero: {
        background_color: '#0F0C24',
    },
    services: ['Strategy', 'UX design', 'Development'],
    client_details: {
        name: 'BlastGalaxy',
        address: {
            street: 'Mt. Lincolnweg 17',
            zip_code: '1033SN',
            city: 'Amsterdam',
        },
        website: 'https://blastgalaxy.nl',
    },
    the_client: {
        title: 'The client',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'Blast Galaxy is an experiential destination for retro gaming fanatics. Based in Noord-Amsterdam, the business brings the soul and spirit of 80s arcade culture to a modern club environment, giving businesses and groups a chance to try their hand at Pac Man, Space Invaders, and more than 100 other on-period games.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'With a loyal and growing digital fan base, Blast Galaxy realised it had a new online opportunity on their hands. The team decided they needed a new web presence to meet a different kind of gaming demand.',
            },
        ],
    },
    the_project: {
        title: 'The objective',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'To grow digital revenues and extend its online presence, Blast galaxy wanted a website with the spirit of an 80s arcade. It had to offer a credible retro vibe, era-relevant games, plus the laser-zap sounds and angular visuals that defined the decade.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'We advised them on how to extend the club’s physical gaming experience online, and also how to position it against competing offers.',
            },
        ],
    },
    tech_stack: {
        title: 'Tech Stack',
        text: [
            'We used WordPress as the CMS, making updates and site management easy for the client. The interactive galaxy background and hyper-space travel graphics were built with Canvas.',
            'Sprite animations combined with Highway.js were used to smooth the transitions between pages, with additional animations built using the the GSAP animation library.',
            "To enable seamless page loads, we used Google's Quicklink and established pre-fetching of pages to make everything fast and responsive",
        ],
        tools: [DEVTOOLS.WORDPRESS, DEVTOOLS.CANVAS, DEVTOOLS.GSAP],
    },
    traveling: {
        title: 'Traveling to the <br/> blast galaxy',
    },
    the_concept: {
        title: 'The big idea',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'We began by asking ‘what if Blast Galaxy was a video game? What would it look, feel, and sound like?',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'We proposed a site that mimicked a traditional old school arcade game, using the same sounds, gaudy colours, and gritty animations. Then we filled it with pop culture references to 80s comic books, soda brands, and ‘rad’ language.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'The idea was to make users feel like they were hanging out in an arcade, with everything reinforcing a back-in-the-day nostalgia vibe.',
            },
        ],
    },
    planetary_system: {
        title: 'The planetary system',
        text: [
            'To bring the Blast Galaxy game to life, each page was designed to represent a different part of the solar system. We built a series of chunky graphics with an old-school 8-bit feel and created planet-themed pages that were visually distinct.',
        ],
        planets: [
            {
                title: 'Explore Echo, all about Blast Galaxy',
                name: 'echo',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/echo.gif',
                    alt: 'Planet Echo',
                },
            },
            {
                title: 'Dust, the planet of games',
                name: 'dust',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/dust.gif',
                    alt: 'Planet Dust',
                },
            },
            {
                title: 'Gremlin5, the galaxy of high scores',
                name: 'gremlin5',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/gremlin5.gif',
                    alt: 'Planet Gremlin5',
                },
            },
            {
                title: 'Plan your trip to earth',
                name: 'earth',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/earth.gif',
                    alt: 'Planet Earth',
                },
            },
            {
                title: 'Cheeto is all about food',
                name: 'Cheeto',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/cheeto.gif',
                    alt: 'Planet Cheeto',
                },
            },
            {
                title: 'Nugget will answer your questions',
                name: 'nugget',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/nugget.gif',
                    alt: 'Planet Nugget',
                },
            },
            {
                title: 'Lunda, the galaxy where events take place',
                name: 'lunda',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/lunda.gif',
                    alt: 'Planet Lunda',
                },
            },
            {
                title: 'Stay in touch with Lava',
                name: 'lava',
                thumbnail: {
                    path: 'projects/blast-galaxy/planets/lava.gif',
                    alt: 'Planet Lava',
                },
            },
        ],
    },
    weekly_highscores: {
        title: 'Weekly highscores',
        image_alt: 'Weekly highscores Blast galaxy',
        text: [
            'We also added functions like gaming tournaments for groups and business accounts to promote and sustain engagement and participation, plus recognised weekly individual high-scorers.',
        ],
    },
    arcade_frame_intro: {
        title: 'from galaxy to <span>arcade</span>',
    },
    arcade_frame_explained: {
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'We wanted to render screen graphics with 80s-era monitor frames, embedded elements like scanlines, and even built-in standard game glitches that would have been common at the time.',
            },
        ],
    },
    glitch: {
        title: 'Did you ever turn on an arcade? <br/> This is what that feels like',
        video: {
            path: '/videos/blast-galaxy-intro.mp4',
        },
        text_pop_up:
            'Warning: Starting the system may be triggering when being epileptic due to glitching',
        text_sound: 'Want to hear what that sounds like?',
    },
    navigation: {
        title: '80’s navigation',
        first_text: [
            'In keeping with the tech limitations of the era, we decided to make everything on the site navigable with arcade-like features like thumbsticks and big buttons. ',
        ],
        second_text: [
            "Menus were designed to be simple and get you back to the home page quickly. Moving between pages would trigger an impactful, full-screen animation suggesting the 'whoosh' of warp-speed space travel. ",
        ],
    },
    assets: {
        title: 'Old Skool assets',
        text: [
            'A lot of arcade game UI elements were borrowed and updated using gif-like animations. Everything we rendered had a pixelated, green-screen feel.',
        ],
    },
    review: {
        title: 'Together we create',
        text: [
            'The Pack is a group of experts with great passion for their profession and an inexhaustible commitment to our digital needs. They use a targeted, no-nonsense and structured approach and that has resulted in an award-winning website. Because of The Pack we can focus on our core tasks with the support of them.',
        ],
        from: 'Chris - Founder - Blast Galaxy',
    },
    project_question: {
        title: 'Got a question about this project? <br/> Ask |EMPLOYEE|',
        employee: mikeyHaklander,
    },
    other_projects: {
        title: 'Other <span>projects</span> we worked on',
        projects: [
            {
                path: '/work/van-wind-naar-watt',
                title: 'How to enhance education with a digital platform?',
                thumbnail: {
                    background_color: '#01689B',
                    image: {
                        path: 'projects/van-wind-naar-watt/van-wind-naar-watt-thumbnail.png',
                        alt: 'Van wind naar watt',
                    },
                },
            },
            {
                title: 'How to support the business owners of Haarlem during a global lockdown?',
                thumbnail: {
                    background_color: '#c8293a',
                    image: {
                        path: 'projects/haarlemse-winkels/haarlemse-winkels-thumbnail.png',
                        alt: 'Haarlemse winkels',
                    },
                },
                live_demo: 'https://haarlemsewinkels.nl',
            },
            {
                title: 'How to shred the tattoo shop stigma with a clients first web experience?',
                thumbnail: {
                    background_color: '#91bfc0',
                    image: {
                        path: 'projects/le-nou-tattou/le-nou-tattou-thumbnail.png',
                        alt: 'Le Nou Tattou - Art in Ink',
                    },
                },
                live_demo: 'https://lenou.nl',
            },
        ],
    },
};
