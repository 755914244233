const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 200,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
};

export default settings;
