import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

//Data
import settings from './settings';

//Styles
import styles from './PlanetarySystem.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { Cursor } from '@components/Cursor/Cursor';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';
import { Planet } from './Planet';

export const PlanetarySystem = props => {
    const { title, text, planets } = props;
    const [cursorActive, setCursorActive] = useState(false);
    const controls = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
    const shouldReduceMotion = useReducedMotion();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [inView, controls]);

    const variants = !shouldReduceMotion
        ? {
              hidden: {
                  opacity: 0,
                  x: '100%',
              },
              visible(delay) {
                  return {
                      opacity: 1,
                      x: 0,
                      transition: {
                          delay: delay * 0.25,
                          duration: 1,
                      },
                  };
              },
          }
        : {};

    return (
        <section className={styles.planetarySystem}>
            <Container>
                <div className={styles.planetarySystem__info}>
                    {title && (
                        <FadeInWhenVisible>
                            <h2 className={`h4`}>{title}</h2>
                        </FadeInWhenVisible>
                    )}

                    {text &&
                        text.map((p, index) => {
                            return (
                                <FadeInWhenVisible threshold={0.15} key={index}>
                                    <p className="big">{p}</p>
                                </FadeInWhenVisible>
                            );
                        })}
                </div>

                {/* Global planetary system slider styling found in styles/sliders/planetary-system-slider.css */}
                <motion.div
                    ref={ref}
                    role="presentation"
                    onMouseEnter={() => setCursorActive(true)}
                    onMouseLeave={() => setCursorActive(false)}
                >
                    <Slider
                        className={`global-planetary-system-slider ${styles.planetarySystem__slider}`}
                        {...settings}
                    >
                        {planets.map((planet, index) => {
                            const { title, name, thumbnail } = planet;

                            return (
                                <motion.div
                                    variants={variants}
                                    custom={index * 0.25}
                                    initial="hidden"
                                    animate={controls}
                                    className={styles.planetarySystem__slide}
                                    key={name}
                                >
                                    {title && <h4 className="h6">{title}</h4>}

                                    <Planet
                                        className={styles.planetarySystem__planet}
                                        path={thumbnail.path}
                                        alt={thumbnail.alt}
                                    />
                                </motion.div>
                            );
                        })}
                    </Slider>

                    <Cursor cursorActive={cursorActive} type="slider-red" />
                </motion.div>
            </Container>
        </section>
    );
};
