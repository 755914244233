import React from 'react';

//Styles
import styles from './Content.module.scss';

//Lib
import { CONTENT_COMPONENT_TYPES } from '@lib/constants';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const Content = props => {
    const { fullHeight = false, title = '', components = [], small } = props;

    if (!components) {
        return null;
    }

    return (
        <section className={`${styles.content} ${fullHeight ? styles.contentFullHeight : ''}`}>
            <Container {...(small ? { small } : {})}>
                {title && (
                    <FadeInWhenVisible>
                        <h2
                            className={`h3 ${styles.content__title}`}
                            dangerouslySetInnerHTML={{ __html: title }}
                        ></h2>
                    </FadeInWhenVisible>
                )}

                {components.map((comp, componentIndex) => {
                    const type = comp.type;

                    // Title
                    if (type === CONTENT_COMPONENT_TYPES.TITLE) {
                        if (!comp.text) return null;

                        return (
                            <FadeInWhenVisible key={componentIndex}>
                                <h3 className={`${styles.content__subTitle} h3`}>{comp.text}</h3>
                            </FadeInWhenVisible>
                        );
                    }

                    // Text
                    if (type === CONTENT_COMPONENT_TYPES.TEXT) {
                        if (!comp.text) return null;

                        return (
                            <FadeInWhenVisible threshold={0.15} key={componentIndex}>
                                <p className="big">{comp.text}</p>
                            </FadeInWhenVisible>
                        );
                    }

                    // List
                    if (type === CONTENT_COMPONENT_TYPES.LIST) {
                        if (!comp.items.length) return null;

                        return (
                            <FadeInWhenVisible key={componentIndex}>
                                {comp.title && <h4 className="h4">{comp.title}</h4>}

                                <ul className={styles.content__list}>
                                    {comp.items.map((item, itemIndex) => {
                                        return (
                                            <li className="default-list-item" key={itemIndex}>
                                                {item}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </FadeInWhenVisible>
                        );
                    }

                    return null;
                })}
            </Container>
        </section>
    );
};
